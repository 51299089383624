function buildProduct(quoteProduct) {
  const product = {
    IdQuotesCircuitsProduct: quoteProduct.product.IdQuotesCircuitsProduct,
    FK_IdQuote: quoteProduct.product.FK_IdQuote,
    FK_IdProduct: quoteProduct.product.FK_IdProduct,
    Token: quoteProduct.product.Token,
    outDate: quoteProduct.product.outDate,
    UnitPriceOrigin: quoteProduct.product.UnitPriceOrigin,
    UnitPriceType: quoteProduct.product.UnitPriceType,
    UnitPrice: quoteProduct.product.UnitPrice,
    Subtotal: quoteProduct.product.Subtotal,
    Status: quoteProduct.product.Status,
    Delivered: quoteProduct.product.Delivered,
    DeliveredMethod: quoteProduct.product.MethodDelivery,

    IdProduct: quoteProduct.infoProduct.IdProduct,
    FK_IdBrand: quoteProduct.infoProduct.FK_IdBrand,
    FK_IdCategory: quoteProduct.infoProduct.FK_IdCategory,
    FK_IdPaint: quoteProduct.infoProduct.FK_IdPaint,
    FK_IdTapestry: quoteProduct.infoProduct.FK_IdTapestry,
    Code: quoteProduct.infoProduct.Code,
    Name: quoteProduct.infoProduct.Name,
    Model: quoteProduct.infoProduct.Model,
    Type: quoteProduct.infoProduct.Type,
    Item: quoteProduct.infoProduct.Item,
    Destiny: quoteProduct.infoProduct.Destiny,
    keyCodeSat: quoteProduct.infoProduct.keyCodeSat,
    Qty: quoteProduct.product.Qty,
    QtyIventory: quoteProduct.infoProduct.Qty,
    QtyLowInventory: quoteProduct.infoProduct.QtyLowInventory,
    PricesDefined: quoteProduct.infoProduct.PricesDefined,
    PriceMin: quoteProduct.infoProduct.PriceMin,
    PriceMax: quoteProduct.infoProduct.PriceMax,
    PricesDefinedWholesaler: quoteProduct.infoProduct.PricesDefinedWholesaler,
    PriceMinWholesaler: quoteProduct.infoProduct.PriceMinWholesaler,
    PriceMaxWholesaler: quoteProduct.infoProduct.PriceMaxWholesaler,
    // PriceSt: quoteProduct.infoProduct.PriceSt,
    PriceLf: quoteProduct.infoProduct.PriceLf,
    PriceDr: quoteProduct.infoProduct.PriceDr,
    MaxSuggestedPrice: quoteProduct.infoProduct.MaxSuggestedPrice,
    UnitPriceInvoiceUsd: quoteProduct.infoProduct.UnitPriceInvoiceUsd,
    Tc: quoteProduct.infoProduct.Tc,
    UnitPriceInvoiceMn: quoteProduct.infoProduct.UnitPriceInvoiceMn,
    Ccost: quoteProduct.infoProduct.Ccost,
    ProvidersPriceUsd: quoteProduct.infoProduct.ProvidersPriceUsd,
    ProvidersCostingMn: quoteProduct.infoProduct.ProvidersCostingMn,
    Weight: quoteProduct.infoProduct.Weight,
    TypeWeight: quoteProduct.infoProduct.TypeWeight,
    Length: quoteProduct.infoProduct.Length,
    Width: quoteProduct.infoProduct.Width,
    Heigh: quoteProduct.infoProduct.Heigh,
    Delete: quoteProduct.infoProduct.Delete,
    created_at: quoteProduct.infoProduct.created_at,
    IdBrand: quoteProduct.infoProduct.IdBrand,
    name_brand: quoteProduct.infoProduct.name_brand,
    IdCategory: quoteProduct.infoProduct.IdCategory,
    name_categorie: quoteProduct.infoProduct.name_categorie,
    IdColorPaint: quoteProduct.infoProduct.IdColorPaint,
    NamePaint: quoteProduct.infoProduct.NamePaint,
    CodePaint: quoteProduct.infoProduct.CodePaint,
    IdColorTapestry: quoteProduct.infoProduct.IdColorTapestry,
    NameTapestry: quoteProduct.infoProduct.NameTapestry,
    CodeTapestry: quoteProduct.infoProduct.CodeTapestry,
    SoldOut: quoteProduct.infoProduct.SoldOut,
    TrueCategory: quoteProduct.infoProduct.TrueCategory,
    OfferPrice: quoteProduct.infoProduct.OfferPrice,

    images: quoteProduct.images,

    pieces: quoteProduct.pieces,
  }

  return product
}

export default buildProduct
